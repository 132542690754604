import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import usePagination from './use-pagination'
import UnstyledButton from 'components/button/unstyled-button'
import { BREAK, NEXT, PREV } from './constants'
import noop from 'utils/noop'
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight'
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft'

const Pagination = ({
  className,
  createLink,
  currentPage,
  enableDefaultStyles,
  onPageChange,
  pageNeighbors,
  paginationBreakClassName,
  paginationCurrentItemClassName,
  paginationItemClassName,
  paginationNextClassName,
  paginationPrevClassName,
  totalPages,
  ...props
}) => {
  const { first, last, pages } = usePagination({ currentPage, pageNeighbors, totalPages })

  const handlePrevPageClick = () => {
    onPageChange(currentPage - 1)
  }

  const handleNextPageClick = () => {
    onPageChange(currentPage + 1)
  }

  const handleFirstPageClick = () => {
    onPageChange(1)
  }

  const handleLastPageClick = () => {
    onPageChange(totalPages)
  }
  return (
    <Nav
      aria-label='pagination'
      className={`${enableDefaultStyles ? 'pagination-default-style' : ''} ${className}`}
      {...props}
    >
      <PaginationList>
        {currentPage !== 1 && (
          <PaginationListItem className={`pagination-prev ${paginationPrevClassName}`}>
            {createLink(PREV, { onClick: handlePrevPageClick }, currentPage)}
          </PaginationListItem>
        )}
        {first !== 1 && (
          <>
            <PaginationListItem className={`pagination-item ${paginationItemClassName}`}>
              {createLink(1, { onClick: handleFirstPageClick }, currentPage)}
            </PaginationListItem>
            <PaginationListItem className={`pagination-break ${paginationBreakClassName}`}>
              {createLink(BREAK, {}, currentPage)}
            </PaginationListItem>
          </>
        )}
        {pages.map(n => (
          <PaginationListItem
            key={`PaginationListItem${n}`}
            className={`pagination-item ${paginationItemClassName} ${
              n === currentPage ? `pagination-current ${paginationCurrentItemClassName}` : ''
            }`}
          >
            {createLink(
              n,
              {
                onClick: () => onPageChange(n),
              },
              currentPage
            )}
          </PaginationListItem>
        ))}
        {last !== totalPages && (
          <>
            <PaginationListItem className={`pagination-break ${paginationBreakClassName}`}>
              {createLink(BREAK, {}, currentPage)}
            </PaginationListItem>
            <PaginationListItem className={`pagination-item ${paginationItemClassName}`}>
              {createLink(totalPages, { onClick: handleLastPageClick }, currentPage)}
            </PaginationListItem>
          </>
        )}
        {currentPage !== totalPages && (
          <PaginationListItem className={`pagination-next ${paginationNextClassName}`}>
            {createLink(NEXT, { onClick: handleNextPageClick }, currentPage)}
          </PaginationListItem>
        )}
      </PaginationList>
    </Nav>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  createLink: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  enableDefaultStyles: PropTypes.bool,
  onPageChange: PropTypes.func,
  pageNeighbors: PropTypes.number,
  paginationBreakClassName: PropTypes.string,
  paginationCurrentItemClassName: PropTypes.string,
  paginationItemClassName: PropTypes.string,
  paginationNextClassName: PropTypes.string,
  paginationPrevClassName: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
}

Pagination.defaultProps = {
  className: '',
  createLink: (n, props = {}, currentPage) => {
    switch (n) {
      case NEXT:
        n = <FiChevronRight aria-hidden='true' focusable='false' role='presentation' />
        props['aria-label'] = 'Next'
        break
      case PREV:
        n = <FiChevronLeft aria-hidden='true' focusable='false' role='presentation' />
        props['aria-label'] = 'Previous'
        break
      case BREAK:
        n = <>&hellip;</>
        props.as = 'div'
        props['aria-hidden'] = true
        break
      case currentPage:
        props['aria-current'] = 'page'
        break
      default:
        break
    }
    return <DefaultButton {...props}>{n}</DefaultButton>
  },
  enableDefaultStyles: true,
  onPageChange: noop,
  pageNeighbors: 2,
  paginationBreakClassName: '',
  paginationCurrentItemClassName: '',
  paginationItemClassName: '',
  paginationNextClassName: '',
  paginationPrevClassName: '',
}

const Nav = styled.nav`
  &.pagination-default-style {
    ul {
      align-items: stretch;
      border-left: 1px solid #d7d7d7;
      display: inline-flex;
      flex-wrap: wrap;
    }

    li {
      align-content: center;
      align-items: stretch;
      background-color: #fafafa;
      border-bottom: 1px solid #d7d7d7;
      border-right: 1px solid #d7d7d7;
      border-top: 1px solid #d7d7d7;
      display: flex;
      min-width: 40px;
    }

    .pagination-current {
      > button,
      > a {
        background-color: #377539;
        color: #fff;
        font-weight: 600;

        :hover,
        :focus {
          text-decoration: underline;
        }
      }
    }

    .pagination-item:not(.pagination-current),
    .pagination-next,
    .pagination-prev {
      > button,
      > a {
        color: #444;

        :hover,
        :focus {
          background-color: #dfdfdf;
          text-decoration: underline;
        }
      }
    }

    svg {
      color: #444;
      vertical-align: middle;
    }
  }
`

const PaginationList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const PaginationListItem = styled.li`
  display: inline-block;
  margin: 0;
  padding: 0;
`

const DefaultButton = styled(UnstyledButton)`
  padding: 8px;
  text-align: center;
  width: 100%;
`

export default Pagination
