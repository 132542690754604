import React from 'react'
import BlogTemplate from 'templates/blog-template'
import { graphql } from 'gatsby'

const BlogCategory = props => {
  return <BlogTemplate header={`Blog - ${props.pageContext.category}`} {...props} />
}

export const pageQuery = graphql`
  query blogCategory($category: String!) {
    allSanityBlogPost(
      sort: { fields: publishedDate, order: DESC }
      filter: { category: { titlePlural: { eq: $category } }, publishedDate: { ne: null } }
    ) {
      nodes {
        authors
        category {
          slug
          titlePlural
        }
        id
        featuredImage {
          asset {
            gatsbyImageData(width: 750)
          }
        }
        slug
        title
      }
    }
  }
`

export default BlogCategory
