import { useState, useEffect } from 'react'
import createNumberRange from 'utils/create-number-range'

const usePagination = ({ currentPage, pageNeighbors, totalPages }) => {
  const [first, setFirst] = useState()
  const [last, setLast] = useState()
  const [pages, setPages] = useState([])

  useEffect(() => {
    const startPage = Math.max(1, currentPage - pageNeighbors)
    const endPage = Math.min(totalPages, currentPage + pageNeighbors)
    const pagesRange = createNumberRange(startPage, endPage)

    setFirst(startPage)
    setLast(endPage)
    setPages(pagesRange)
  }, [currentPage, pageNeighbors, totalPages])

  return { first, last, pages }
}

export default usePagination
