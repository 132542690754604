import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content-full-width'
import { GatsbyImage } from 'gatsby-plugin-image'
import { navigate, Link } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Pagination from 'components/pagination'
import { useEffectOnce } from 'hooks/use-effect-once'

const FIRST_PAGE_SHOWING = 10
const DEFAULT_SHOWING = 12

const BlogTemplate = props => {
  const {
    data: {
      allSanityBlogPost: { nodes: blogPosts },
    },
    location,
    header,
  } = props

  const topEl = useRef(null)
  const [page, setPage] = useState(1)

  const onBackButtonEvent = e => {
    const params = new URLSearchParams(window.location.search)
    const page = params.get('page')
    if (/\d/.test(page)) {
      setPage(Number(page))
    } else {
      setPage(1)
    }
  }

  useEffectOnce(() => {
    window.addEventListener('popstate', onBackButtonEvent)

    const { search } = location

    if (search) {
      const params = new URLSearchParams(search)
      const page = params.get('page')
      if (/\d/.test(page)) {
        setPage(Number(page))
      } else {
        setPage(1)
      }
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  })

  const showing = page === 1 ? FIRST_PAGE_SHOWING : DEFAULT_SHOWING
  const posts = []
  const postsStart = page === 1 ? 1 : DEFAULT_SHOWING * (page - 2) + FIRST_PAGE_SHOWING
  for (
    let i = postsStart;
    i < blogPosts.length && i < postsStart + showing - (page === 1 ? 1 : 0);
    i++
  ) {
    posts.push(blogPosts[i])
  }
  const totalPages =
    blogPosts.length <= FIRST_PAGE_SHOWING
      ? 1
      : Math.ceil(1 + (blogPosts.length - FIRST_PAGE_SHOWING) / DEFAULT_SHOWING)

  const handlePageChange = newPage => {
    if (page !== newPage) {
      setPage(newPage)

      navigate(`${location.pathname}?page=${newPage}`)

      topEl.current.focus()
    }
  }
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{header} | Hutson Inc</title>
      </Helmet>
      <Content>
        <Title ref={topEl} tabIndex='-1'>
          {header}
        </Title>
        {page === 1 && <Post {...blogPosts[0]} first={true} />}
        <Grid>
          {posts.map(post => (
            <Post {...post} key={post.id} />
          ))}
        </Grid>
        {totalPages > 1 && (
          <Pagination onPageChange={handlePageChange} currentPage={page} totalPages={totalPages} />
        )}
      </Content>
    </Layout>
  )
}

const Title = styled.h1`
  margin-top: 0;

  :focus {
    outline: none;
  }
`

const Grid = styled.div`
  @media (min-width: 800px) {
    ${clearfix}
    display: flex;
    flex-wrap: wrap;
  }
`

const Post = props => {
  const { authors, category, first, featuredImage, slug, title } = props
  return (
    <PostContainer className={first ? 'featured-post' : ''}>
      <ImageColumn className={first ? 'featured-post-image' : ''}>
        <RatioContainer>
          <PostImageLink to={slug}>
            <GatsbyImage
              image={featuredImage.asset.gatsbyImageData}
              alt=''
              objectFit='cover'
              objectPosition='50% 50%'
              style={{ width: '100%', height: '100%' }}
            />
          </PostImageLink>
        </RatioContainer>
      </ImageColumn>
      <DetailsColumn className={first ? 'featured-post-details' : ''}>
        <div>
          {category ? (
            <CategoryLink to={category.slug}>{category.namePlural}</CategoryLink>
          ) : (
            <CategoryLink to={`/blog/`}>Blog</CategoryLink>
          )}
        </div>
        <PostTitleLink to={slug}>
          <PostTitle>{title}</PostTitle>
        </PostTitleLink>
        {authors ? <PostAuthor>by {authors}</PostAuthor> : null}
      </DetailsColumn>
    </PostContainer>
  )
}

const PostContainer = styled.div`
  background-color: ${props => props.theme.color.n10};
  margin-bottom: 30px;

  @media (min-width: 800px) {
    margin-bottom: 48px;

    :not(.featured-post) {
      ${column('1/2')}
    }

    &.featured-post {
      ${clearfix}
      align-items: center;
      display: flex;
    }
  }

  @media (min-width: 1200px) {
    :not(.featured-post) {
      ${column('1/3')}
    }
  }
`

const ImageColumn = styled.div`
  @media (min-width: 800px) {
    &.featured-post-image {
      ${column('3/5', 0)}
    }
  }
`

const RatioContainer = styled.div`
  display: block;
  height: 0px;
  overflow: hidden;
  padding-bottom: 62.5%;
  position: relative;
  width: auto;
`

const PostImageLink = styled(Link)`
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
`

const DetailsColumn = styled.div`
  padding: 24px 16px;

  @media (min-width: 800px) {
    &.featured-post-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 48px;
      ${column('2/5', 0)}
    }
  }
`

const CategoryLink = styled(Link)`
  color: ${props => props.theme.color.g400};
  display: inline;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const PostTitleLink = styled(Link)`
  color: ${props => props.theme.color.n900};
  display: block;
  margin-top: 16px;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const PostTitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  padding: 0;
  margin: 0;
`

const PostAuthor = styled.span`
  color: ${props => props.theme.color.n800};
  display: block;
  font-weight: 400;
  margin-top: 16px;
`

export default BlogTemplate
