/**
 * Creating a range of numbers
 * @param {number} from - Starting number
 * @param {number} to - nding number
 * @param {number} [step=1] - Number to step by
 * @example
 * range(1, 5)
 * // returns [1, 2, 3, 4, 5]
 * @returns {number[]} Range of numbers
 */
const createNumberRange = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

module.exports = createNumberRange
